import { graphql } from "gatsby";
import React from "react";
import Circle1 from "../images/main-circles/circle-1.png";
import Circle2 from "../images/main-circles/circle-2.png";
import { FooterNav } from "../components/FooterNav";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const BlogPost = ({ location, data }) => {
  const { title, text, img } = data.json;

  return (
    <Layout location={location}>
      <Seo className={"scroll license-bg"} title={title} />
      <div className="blog-img-preview">
        <div>
          <div className="blog-img-box">
            <img
              src={img}
              alt={title}
              // height="555px"
              // style={{
              //   objectFit: "cover",
              //   objectPosition: "center",
              //   position: "relative",
              //   zIndex: 3,
              // }}
            />
          </div>
        </div>
      </div>
      <div style={{ position: "relative", flex: "1 1 0%" }}>
        <div
          className="container full color-white license-page"
          style={{ display: "block", paddingTop: "40px" }}
        >
          <h1>{title}</h1>

          <p>{text}</p>
        </div>
        <img
          src={Circle1}
          alt="Duqqy"
          className="main-circle main-circle-1 term-circle"
        />
        <img
          src={Circle2}
          alt="Duqqy"
          className="main-circle main-circle-2 term-circle"
        />
      </div>
      <FooterNav location={location} />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query Post($url: String) {
    json(url: { eq: $url }) {
      title
      img
      text
    }
  }
`;
